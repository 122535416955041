import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppSidebar from '../../components/AppSidebar/AppSidebar';
import { Box } from '@mui/material';
import ChatContainer from '../Chat/ChatContainer';
import NotificationContainer from '../Notification/NotificationContainer';
import AuthorizationModal from '../../components/Authorization/AuthorizationModal';
import { IAuthorizationInfo } from '../../types/common.type';
import AirbetLogo from '../../api/ui/svgIcons/AirbetLogo';
import { connect } from 'react-redux';
import CountryRestrictionModal from '../../components/CountryRestrictionModal/CountryRestrictionModal';
import UserProfileModal from '../../components/UserProfile/UserProfileModal';
import { bindActionCreators } from 'redux';
import {
  changeGlobalUserRegistrationAndLoginModalClose,
  changeMainHeaderVisibility,
} from '../../redux/actions/userActions';
import { isMobile } from 'react-device-detect';
import BottomMenuMobile from '../../components/Menu/BottomMenuMobile';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';
import ResetPasswordModal from '../../components/Authorization/ResetPasswordModal';
import { forgotPassword } from '../../api/services/Auth.api';
import { enqueueSnackbar } from 'notistack';
import MainFooter from '../../components/MainFooter/MainFooter';
import Container from '../../components/common/BoxContainer/Container';
import { scrollToTop, setLocalStorageValue } from '../../util/appUtil';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { LiveChatWidget } from "@livechat/widget-react"

interface IMainLayoutWrapper {
  children: ReactNode;
  mainLoading: boolean;
  isCountryRestricted: boolean;
  globalUserLoginModalOpen: boolean;
  globalUserRegistrationModalOpen: boolean;
  changeGlobalUserRegistrationAndLoginModalClose: (open: boolean) => void;
  changeMainHeaderVisibility: (visible: boolean) => void;
  isUserAuthenticated: any;
}

const useStyles = makeStyles(() => ({
  main: { background: '#0D121D' },
  root: {
    background: 'black',
    width: '100%',
    height: '100vh',
    display: 'flex',
    minHeight: '0px',
    minWidth: '0px',
    boxSizing: 'border-box',
    userSelect: 'none',
    transition: 'opacity 0.5s ease-in-out',
    flexDirection: isMobile ? 'column' : 'row',
  },
  content: {
    display: 'flex',
    minHeight: '0px',
    minWidth: '0px',
    boxSizing: 'border-box',
    height: '100%',
  },
  body: {
    width: '100%',
    minWidth: '0px',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    marginBottom: isMobile ? '61px' : '0px',
  },
  chatAndNotificationContainer: {
    height: '100%',
    transition: 'min-width 0.2s ease-in-out',
    boxShadow: '-2px 0px 4px 0px rgba(3, 7, 18, 0.50)',
    background: '#030712',
    borderLeft: '1px solid rgba(255, 255, 255, 0.05)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chatAndNotificationContainerContent: {
    display: 'flex',
    height: '100%',
    transition: 'width 0.3s',
    position: 'absolute',
    top: '0px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    animation: '$pulse infinite 2s ease-in-out',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
  indicator: {
    background: '#3B82F6',
    width: '0%',
    height: '8px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    position: 'absolute',
    top: 0,
    left: 0,
    animation: '$progress 3s ease-in-out',
  },
  '@keyframes progress': {
    '0%': {
      width: '0%',
    },
    '30%': {
      width: '30%',
    },
    '50%': {
      width: '50%',
    },
    '100%': {
      width: '100%',
    },
  },
}));

const MainLayoutWrapper = ({
  children,
  mainLoading,
  isCountryRestricted,
  globalUserLoginModalOpen,
  globalUserRegistrationModalOpen,
  changeGlobalUserRegistrationAndLoginModalClose,
  changeMainHeaderVisibility,
  isUserAuthenticated,
}: IMainLayoutWrapper) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(!isMobile);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [authorizationInfo, setAuthorizationInfo] =
    useState<IAuthorizationInfo>({
      isLoginOpen: false,
      isRegistrationOpen: false,
    });
  const classes = useStyles({
    isSidebarOpen,
    isChatOrNotificationOpen: isChatOpen || isNotificationOpen,
  });
  const [openRestrictedCountryModal, setOpenRestrictedCountryModal] =
    useState<boolean>(false);
  const [selectedPageMobileVersion, setSelectedPageMobileVersion] =
    useState<string>('lobby');
  const [isOpenResetPasswordModal, setIsOpenResetPasswordModal] =
    useState<boolean>(false);
  const airbetMainWrapperBodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (!isMobile) {
        const contentElement: any = airbetMainWrapperBodyRef.current;
        if (window.innerWidth < 950 && (isSidebarOpen || isChatOpen)) {
          if (contentElement) {
            contentElement.style.opacity = '0.2';
            contentElement.style.position = 'absolute';
            document.body.style.overflow = 'hidden';
            contentElement.style.paddingLeft = '64px';
          }
        } else {
          setTimeout(() => {
            contentElement.style.opacity = '1';
            document.body.style.overflow = 'auto';
            contentElement.style.position = 'static';
            contentElement.style.paddingLeft = '0px';
          }, 300);
        }
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isSidebarOpen, isChatOpen]);

  useEffect(() => {
    if (isMobile && location) {
      changeMainHeaderVisibility(
        location.pathname.includes('wallet') ||
          location.pathname.includes('account') ||
          location.pathname.includes('settings')
      );

      if (location.pathname.includes('casino')) {
        setSelectedPageMobileVersion('casino');
      }

      if (location.pathname.includes('sport')) {
        setSelectedPageMobileVersion('sport');
      }
    }
    if (!location.pathname.includes('auth')) {
      setLocalStorageValue('lastOpenedPage', location.pathname);
    }
    scrollToTop();
  }, [location]);

  useEffect(() => {
    if (isMobile) {
      changeMobilePageHandler();
    }
  }, [selectedPageMobileVersion]);

  useEffect(() => {
    if (isCountryRestricted) {
      setOpenRestrictedCountryModal(isCountryRestricted);
    }
  }, [isCountryRestricted]);

  useEffect(() => {
    if (isMobile && !isSidebarOpen) {
      if (location.pathname.includes('wallet')) {
        setSelectedPageMobileVersion('wallet');
      } else if (
        location.pathname.includes('account') ||
        location.pathname.includes('settings')
      ) {
        setSelectedPageMobileVersion('account');
      } else if (location.pathname.includes('chat')) {
        setSelectedPageMobileVersion('chat');
      } else if (location.pathname.includes('casino')) {
        setSelectedPageMobileVersion('casino');
      } else if (location.pathname.includes('sport')) {
        setSelectedPageMobileVersion('sport');
      } else if (location.pathname === '/') {
        setSelectedPageMobileVersion('lobby');
      }
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isMobile && !isChatOpen) {
      if (location.pathname.includes('wallet')) {
        setSelectedPageMobileVersion('wallet');
      } else if (
        location.pathname.includes('account') ||
        location.pathname.includes('settings')
      ) {
        setSelectedPageMobileVersion('account');
      } else if (location.pathname.includes('chat')) {
        setSelectedPageMobileVersion('chat');
      } else if (location.pathname.includes('casino')) {
        setSelectedPageMobileVersion('casino');
      } else if (location.pathname.includes('sport')) {
        setSelectedPageMobileVersion('sport');
      } else if (location.pathname === '/') {
        setSelectedPageMobileVersion('lobby');
      }
    } else if (isMobile && isChatOpen) {
      setIsChatOpen(true);
      setIsNotificationOpen(false);
      setSelectedPageMobileVersion('chat');
    }
  }, [isChatOpen]);

  const changeMobilePageHandler = () => {
    setIsChatOpen(false);
    setIsNotificationOpen(false);
    setIsSidebarOpen(false);

    if (selectedPageMobileVersion === 'chat') {
      setIsChatOpen(true);
      setIsNotificationOpen(false);
      setIsSidebarOpen(false);
    }

    if (selectedPageMobileVersion === 'menu') {
      setIsSidebarOpen(true);
      setIsChatOpen(false);
      setIsNotificationOpen(false);
    }

    if (selectedPageMobileVersion === 'account')
      navigate(AppRoutePath.ACCOUNT_MOBILE_PAGE());

    if (selectedPageMobileVersion === 'wallet')
      navigate(AppRoutePath.WALLET_MOBILE_PAGE());

    if (selectedPageMobileVersion === 'sport') navigate(AppRoutePath.SPORT());

    if (selectedPageMobileVersion === 'casino') navigate(AppRoutePath.CASINO());

    if (selectedPageMobileVersion === 'lobby') navigate('/');
  };

  const openLogin = (): void => {
    setAuthorizationInfo({ ...authorizationInfo, isLoginOpen: true });
    const target = document.getElementById('authContent')
    disableBodyScroll(target!);
  };

  const openRegistration = (): void => {
    setAuthorizationInfo({ ...authorizationInfo, isRegistrationOpen: true });
  };

  const onCloseAuthorizationModal = (): void => {
    setAuthorizationInfo({
      isLoginOpen: false,
      isRegistrationOpen: false,
    });
    changeGlobalUserRegistrationAndLoginModalClose(false);
    clearAllBodyScrollLocks();
  };

  const onResetPassword = (email: string) => {
    forgotPassword({ email })
      .then((resp) => {
        if (resp.status === 200) {
          enqueueSnackbar(resp.data.data.message, { variant: 'success' });
        }
      })
      .catch((resp: any) => {
        if (resp.response.data?.data?.error) {
          enqueueSnackbar(resp.response.data.data.error, { variant: 'error' });
        }
      })
      .finally(() => {
        setIsOpenResetPasswordModal(false);
      });
  };

  // Save last visited path to local storage
  useEffect(() => {
    localStorage.setItem('lastVisitedPath', location.pathname);
  }, [location]);

  // Redirect to last visited path
  useEffect(() => {
    const lastVisitedPath = localStorage.getItem('lastVisitedPath');
    if (lastVisitedPath) {
      navigate(lastVisitedPath);
    }
  }, [navigate]);

  return (
    <Box className={classes.main}>
      {mainLoading && (
        <div className={classes.loadingContainer}>
          <div className={classes.indicator} />
          <AirbetLogo />
        </div>
      )}
      <Box
        className={classes.root}
        style={{
          opacity: mainLoading ? 0 : 1,
        }}
      >
        <AppHeader
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          isChatOpen={isChatOpen}
          setIsChatOpen={setIsChatOpen}
          isNotificationOpen={isNotificationOpen}
          setIsNotificationOpen={setIsNotificationOpen}
          openLogin={openLogin}
          openRegistration={openRegistration}
          setSelectedPageMobileVersion={setSelectedPageMobileVersion}
        >
          <div className={classes.content}>
            <AppSidebar
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
            />
            <div
              className={classes.body}
              ref={airbetMainWrapperBodyRef}
              id={'airbet-body'}
              style={{
                opacity: isMobile && isSidebarOpen ? '0' : '',
              }}
            >
              {children}
              <Box
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                  background: '#0D121D',
                  paddingTop: location.pathname.includes('sports')
                    ? '0'
                    : '72px',
                  display:
                    isMobile &&
                    (location.pathname.includes('wallet') ||
                      location.pathname.includes('account') ||
                      location.pathname.includes('settings'))
                      ? 'none'
                      : '',
                }}
              >
                {!location.pathname.includes('sports') && (
                  <Container>
                    <MainFooter isMobile={isMobile} />
                  </Container>
                )}
              </Box>
            </div>
          </div>
        </AppHeader>
        {isMobile && (
          <Box
            style={{
              width: '100%',
              zIndex: 99,
              position: 'fixed',
              bottom: '0px',
            }}
          >
            <BottomMenuMobile
              value={selectedPageMobileVersion}
              setValue={setSelectedPageMobileVersion}
            />
          </Box>
        )}
        <Box
          className={classes.chatAndNotificationContainer}
          minWidth={
            isChatOpen || isNotificationOpen
              ? isMobile
                ? '100%'
                : '320px'
              : '0px'
          }
          style={{
            overflow: 'hidden',
            position: isMobile ? 'absolute' : 'relative',
            zIndex: 999,
          }}
        >
          <Box
            style={{
              width: isChatOpen ? '100%' : '0px',
              transition: 'width 0.2s ease-in-out',
            }}
            className={classes.chatAndNotificationContainerContent}
          >
            {isChatOpen && (
              <ChatContainer isOpen={isChatOpen} setIsOpen={setIsChatOpen} />
            )}
          </Box>
          <Box
            style={{
              width: isNotificationOpen ? '100%' : '0px',
              transition: 'width 0.2s ease-in-out',
              background: '#111827',
            }}
            className={classes.chatAndNotificationContainerContent}
          >
            {isUserAuthenticated && (
              <NotificationContainer
                isOpen={isNotificationOpen}
                setIsOpen={setIsNotificationOpen}
              />
            )}
          </Box>
        </Box>
      </Box>
      <AuthorizationModal
        open={
          authorizationInfo.isLoginOpen ||
          authorizationInfo.isRegistrationOpen ||
          globalUserLoginModalOpen ||
          globalUserRegistrationModalOpen
        }
        onClose={() => onCloseAuthorizationModal()}
        isRegistrationOpen={
          authorizationInfo.isRegistrationOpen ||
          globalUserRegistrationModalOpen
        }
        setIsOpenResetPasswordModal={setIsOpenResetPasswordModal}
      />
      <CountryRestrictionModal
        open={openRestrictedCountryModal}
        setOpen={() => setOpenRestrictedCountryModal(false)}
      />
      <UserProfileModal />
      {isOpenResetPasswordModal && (
        <ResetPasswordModal
          setIsOpenResetPasswordModal={setIsOpenResetPasswordModal}
          isOpenResetPasswordModal={isOpenResetPasswordModal}
          onResetPassword={onResetPassword}
        />
      )}
      <LiveChatWidget license="18177906" />
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  isCountryRestricted: state.user.isCountryRestricted,
  globalUserLoginModalOpen: state.user.globalUserLoginModalOpen,
  globalUserRegistrationModalOpen: state.user.globalUserRegistrationModalOpen,
  isUserAuthenticated: state.user.isUserAuthorized,
});

const dispatchToProps = (dispatch: any) => ({
  changeGlobalUserRegistrationAndLoginModalClose: bindActionCreators(
    changeGlobalUserRegistrationAndLoginModalClose,
    dispatch
  ),
  changeMainHeaderVisibility: bindActionCreators(
    changeMainHeaderVisibility,
    dispatch
  ),
});

export default connect(mapStateToProps, dispatchToProps)(MainLayoutWrapper);
