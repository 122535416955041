import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface IContainer {
  children: ReactNode;
  zIndex?: number | string;
}

const Container = ({ zIndex = 'initial', children }: IContainer) => {
  return (
    <Box
      maxWidth={'1200px'}
      width={'100%'}
      margin={'0px auto'}
      boxSizing={'border-box'}
      zIndex={zIndex}
    >
      {children}
    </Box>
  );
};

export default Container;
