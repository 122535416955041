import React, { FC, SyntheticEvent, useRef, useState } from 'react';
import { IGameSchema } from '../../types/Game.type';
import { makeStyles } from '@mui/styles';
import { Box, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList } from '@mui/lab';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CropFreeIcon from '@mui/icons-material/CropFree';
import Container from '../common/BoxContainer/Container';
import PlayDemoGame from './PlayDemoGame';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Tooltip from '@mui/material/Tooltip';
import { isMobile } from 'react-device-detect';
import PlayGamePageForMobile from './PlayGamePageForMobile';
import { useDispatch } from 'react-redux';
import { changeGlobalUserLoginModalOpen } from '../../redux/actions/userActions';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';
import BetsTableContainer from '../../containers/BetsTable/BetsTableContainer';
import ProviderSwiper from '../Provider/ProviderSwiper';
import GameSwiper from '../common/GameSwiper/GameSwiper';
import AirbetOriginalMenuIcon from '../../api/ui/svgIcons/AirbetOriginalMenuIcon';
import AirbetLogo from '../../api/ui/svgIcons/AirbetLogo';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '40px',
    paddingTop: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  header: {
    borderRadius: '8px 8px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#111827',
    padding: '8px 16px',
  },
  contentWrapper: {
    height: '747px',
    borderRadius: '0px 0px 16px 16px',
    border: '2px solid #111827',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftBoxInHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& span': {
      fontSize: '14px',
      lineHeight: '24px',
    },
    '& span:nth-child(1)': {
      color: '#FFFFFF',
      fontWeight: 700,
    },
    '& span:nth-child(2)': {
      fontWeight: 600,
      color: '#6B7280',
    },
  },
  tabContainer: {
    borderRadius: '8px',
    background: '#0D121D',
    display: 'flex',
    padding: '4px',
    alignItems: 'flex-start',
    gap: '4px',
    width: 'fit-content',

    '& .MuiTabs-root': {
      minHeight: '0px',
    },

    '& .MuiButtonBase-root': {
      color: '#9CA3AF',
      fontSize: '11px',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0px',
      textAlign: 'left',
      minHeight: '0px',
    },
    '& .Mui-selected': {
      color: '#ffffff',
    },
  },
  tab: {
    zIndex: '2',
    position: 'relative',
    display: 'flex',
    padding: '2px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    width: '104px',
    height: '32px',
  },
  rightBoxInHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    height: '32px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    animation: '$pulse infinite 2s ease-in-out',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

interface PlayGamePageProps {
  gameInfo: IGameSchema | null;
  loading: boolean;
  gameUrl: string | undefined;
  isUserAuthenticated: boolean;
  setGameType: (value: string | null) => void;
  gameType: string | null;
  addToFavoriteGameList: (gameId: string) => void;
  removeFromFavoriteGameList: (gameId: string) => void;
  favourite: IGameSchema[];
  recommendedGames: IGameSchema[];
}

const PlayGamePage: FC<PlayGamePageProps> = ({
  gameInfo,
  loading,
  gameUrl,
  isUserAuthenticated,
  setGameType,
  gameType,
  addToFavoriteGameList,
  removeFromFavoriteGameList,
  favourite,
  recommendedGames,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [value, setValue] = useState('real');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openLoginPage = () => {
    dispatch(changeGlobalUserLoginModalOpen(true));
  };

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setGameType(newValue);
  };

  const handleFullScreen = () => {
    iframeRef && iframeRef.current && iframeRef.current.requestFullscreen();
  };

  const isFavourite: boolean = Boolean(
    favourite &&
      gameInfo &&
      favourite.find((item: IGameSchema) => item.id === gameInfo.id)
  );

  const openGamesByProviders = () => {
    if (gameInfo?.provider) {
      navigate(`/slots?provider=${gameInfo?.provider}`);
    }
  };

  return (
    <>
      {!isMobile ? (
        <Box className={classes.root}>
          <Container zIndex={1}>
            <Box className={classes.header}>
              <Box className={classes.leftBoxInHeader}>
                <span>{gameInfo?.game_title}</span>
                <span
                  onClick={() => openGamesByProviders()}
                  style={{ cursor: 'pointer' }}
                >
                  {gameInfo?.provider}
                </span>
              </Box>
              <Box className={classes.rightBoxInHeader}>
                {isUserAuthenticated && (
                  <>
                    <TabContext value={value}>
                      <Box className={classes.tabContainer}>
                        <TabList
                          onChange={handleChange}
                          TabIndicatorProps={{
                            style: {
                              background: '#1F2937',
                              height: '32px',
                              borderRadius: '8px',
                            },
                          }}
                        >
                          <Tab
                            label={t('realPlay')}
                            value="real"
                            className={classes.tab}
                            disableRipple
                          />
                          {gameInfo && gameInfo.provider !== 'Inhouse' && (
                            <Tab
                              label={t('demoPlay')}
                              value="demo"
                              className={classes.tab}
                              disableRipple
                            />
                          )}
                        </TabList>
                      </Box>
                    </TabContext>
                    <div
                      style={{
                        width: '16px',
                        rotate: '-90deg',
                        border: '1px solid #1F2937',
                      }}
                    ></div>
                    <Tooltip
                      title={
                        isFavourite
                          ? t('removeFromFavourites')
                          : t('addToFavourites')
                      }
                      placement="top"
                      arrow={true}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: '#FFF',
                            fontSize: '12px',
                            fontWeight: 700,
                          },
                        },
                      }}
                    >
                      <span
                        onClick={() =>
                          isFavourite
                            ? gameInfo &&
                              removeFromFavoriteGameList(String(gameInfo.id))
                            : gameInfo &&
                              addToFavoriteGameList(String(gameInfo.id))
                        }
                        style={{ height: '21px' }}
                      >
                        {!isFavourite ? (
                          <FavoriteBorderIcon
                            style={{
                              color: '#9CA3AF',
                              cursor: 'pointer',
                              height: '21px',
                            }}
                          />
                        ) : (
                          <FavoriteIcon
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              height: '21px',
                            }}
                          />
                        )}
                      </span>
                    </Tooltip>
                    {gameInfo && gameInfo.provider !== 'Inhouse' && (
                      <Tooltip
                        title={t('fullscreen')}
                        placement="top"
                        arrow={true}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              color: '#FFF',
                              fontSize: '12px',
                              fontWeight: 700,
                            },
                          },
                        }}
                      >
                        <CropFreeIcon
                          style={{
                            color: '#9CA3AF',
                            cursor: 'pointer',
                            height: '21px',
                          }}
                          onClick={handleFullScreen}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box className={classes.contentWrapper}>
              {!loading ? (
                gameType !== null ? (
                  <iframe
                    ref={iframeRef}
                    allowFullScreen={true}
                    style={{
                      width: '100%',
                      height: '100%',
                      boxSizing: 'border-box',
                      borderRadius: '0px 0px 12px 12px',
                      border: 'none',
                    }}
                    src={gameUrl && gameUrl}
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    allow="autoplay; fullscreen; picture-in-picture; allow-scripts"
                    onLoad={(event: any) => {
                      event.target.contentDocument &&
                        navigate(AppRoutePath.HOME());
                    }}
                  />
                ) : (
                  <PlayDemoGame
                    setGameType={setGameType}
                    setIsLoginOpen={() => openLoginPage()}
                    gameInfo={gameInfo}
                  />
                )
              ) : (
                <Box
                  style={{
                    boxSizing: 'border-box',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <div className={classes.loadingContainer}>
                    <AirbetLogo />
                  </div>
                </Box>
              )}
            </Box>
          </Container>
        </Box>
      ) : (
        <PlayGamePageForMobile
          gameInfo={gameInfo}
          isFavourite={isFavourite}
          addToFavoriteGameList={addToFavoriteGameList}
          removeFromFavoriteGameList={removeFromFavoriteGameList}
          gameUrl={gameUrl}
          setGameType={setGameType}
          isUserAuthenticated={isUserAuthenticated}
          setIsLoginOpen={() => openLoginPage()}
          openGamesByProviders={openGamesByProviders}
        />
      )}
      <Box
        style={{
          background: '#0D121D',
          width: '100%',
          padding: isMobile ? '0px 8px' : '0px 24px',
          boxSizing: 'border-box',
        }}
      >
        <Container>
          <GameSwiper
            title={t('recommendedGames')}
            icon={<AirbetOriginalMenuIcon size={isMobile ? 16 : 20} />}
            seeAllLink={AppRoutePath.GAMELIST()}
            games={recommendedGames}
            loading={false}
          />
          <BetsTableContainer />
          <ProviderSwiper />
        </Container>
      </Box>
    </>
  );
};

export default PlayGamePage;
