import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CustomMinimalButton from '../../common/Buttons/CustomMinimalButton';
import RemoveIcon from '../../../api/ui/svgIcons/RemoveIcon';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px',
    padding: '16px',
    background: '#0D121D',
    borderRadius: '8px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    gap: '8px',
    '& p': {
      color: '#67738A',
      fontWeight: 500,
      marginLeft: 70,
    },
    '& div:first-child': {
      color: '#67738A',
      fontWeight: 500,
    },
  },
  inputFile: {
    position: 'relative',
    marginTop: '8px',
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: '14px',
    boxSizing: 'border-box',
    '& input': {
      position: 'absolute',
      inset: 0,
      opacity: 0,
      cursor: 'pointer',
      backgroundColor: 'red',
    },
  },
  attached: {
    boxSizing: 'border-box',
    display: 'flex',
    backgroundColor: '#111827',
    borderRadius: '8px',
    justifyContent: 'space-between',
    padding: '6px 16px',
    border: '1px dashed #67738A',
    alignItems: 'center',
    fontWeight: 700,
    '& div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& svg': {
      cursor: 'pointer',
    },
  },
}));

interface IIdentityItem {
  name: string;
  onAttach: (e: any) => void;
  onRemove: () => void;
  attachedDocument: File | null;
  description: string;
  description2?: string;
}

const IdentityItem = ({
  onAttach,
  onRemove,
  attachedDocument,
  name,
  description,
  description2,
}: IIdentityItem) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onChangeHandler = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const fileSize = file.size / 1024;
      const allowedFileSize = 5000;
      const fileType = file.type.split('/').pop();
      const allowedFileTypes = ['jpg', 'jpeg', 'png', 'pdf'];
      if (!allowedFileTypes.includes(fileType)) {
        enqueueSnackbar(t('invalidFileFormat'), {
          variant: 'error',
        });
        return;
      }

      if (fileSize > allowedFileSize) {
        enqueueSnackbar(t('maxFileSizeIs5000'), {
          variant: 'error',
        });
        return;
      }
      onAttach(e);
    }
  };

  return (
    <Box className={classes.body}>
      <div>{name}</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ textWrap: 'nowrap' }}>{description}</div>
        <p className="desc2">{description2}</p>
      </div>

      <div className={classes.inputFile}>
        {attachedDocument ? (
          <div className={classes.attached}>
            <div>{attachedDocument.name}</div>
            <div onClick={onRemove}>
              <RemoveIcon size={14} />
            </div>
          </div>
        ) : (
          <>
            <CustomMinimalButton style={{ width: '100%' }}>
              <div
                style={{
                  fontWeight: 700,
                  color: '#fff',
                  textTransform: 'uppercase',
                }}
              >
                {t('upload')}
              </div>
            </CustomMinimalButton>
            <input
              type={'file'}
              data-testid={'file'}
              accept=".jpg,.jpeg,.png,.pdf"
              onChange={(e) => onChangeHandler(e)}
            />
          </>
        )}
      </div>
    </Box>
  );
};

export default IdentityItem;
