import { IPromotionDataType } from '../PromotionContainer';
import {
  getFormattedDateWithText,
  getNameByType,
  getStyleByType,
} from '../helper/PromotionHelper';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import PromotionCountDownIcon from '../../../api/ui/svgIcons/PromotionCountDownIcon';
import { getBaseUrlStatic } from '../../../api/baseUrl';
import Container from '../../common/BoxContainer/Container';
import { Box } from '@mui/material';
import CustomMinimalButton from '../../common/Buttons/CustomMinimalButton';
import { ChevronLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../../util/appRoutePath';

interface IPromotionItemPage {
  promotion: IPromotionDataType | null;
  loading: boolean;
  dateInfo: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } | null;
}

const PromotionItemPage = ({ promotion, dateInfo }: IPromotionItemPage) => {
  const { t } = useTranslation();
  const getCountDownByType = () => {
    if (promotion && new Date(promotion.start_date) > new Date()) {
      return (
        <span
          style={{
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: 600,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <PromotionCountDownIcon /> {t('startsIn')}
        </span>
      );
    }

    if (promotion && new Date(promotion.end_date) > new Date()) {
      return (
        <span
          style={{
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: 600,
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <PromotionCountDownIcon /> {t('endsIn')}
        </span>
      );
    }

    return (
      <span
        style={{
          fontSize: '18px',
          lineHeight: '24px',
          fontWeight: 600,
          color: '#FFFFFF80',
        }}
      >
        {t('archived')}
      </span>
    );
  };

  const navigate = useNavigate()

  return (
    promotion && (
      <Box
        style={{
          background: '#0D121D',
          width: '100%',
          boxSizing: 'border-box',
        }}
        sx={{
          '& img': {
            maxWidth: '640px',
          },
        }}
      >
        <Box
          paddingTop={'16px'}
          paddingLeft={'16px'}
        >
          <CustomMinimalButton
            onClick={() => navigate(AppRoutePath.PROMOTION())}
            alignContentOverride='start'
            style={{
              display: 'flex',
              width: '144px',
            }}
          >
            <span
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ChevronLeft />
              {t('airbetMenu.promotions')}
            </span>
          </CustomMinimalButton>
        </Box>
        <Container>
          <div
            style={{
              margin: 'auto',
              maxWidth: '640px',
              marginTop: isMobile ? '16px' : '48px',
              padding: isMobile ? '0px 8px' : '',
            }}
          >
            <div
              style={{
                display: 'flex',
                boxSizing: 'border-box',
                flexDirection: 'column',
              }}
            >
              <div style={{ display: 'flex', gap: '16px' }}>
                <div
                  style={{
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '16px',
                    ...getStyleByType(promotion),
                  }}
                >
                  {getNameByType(promotion)}
                </div>
                <div
                  style={{
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '16px',
                    background: '#1F2937',
                    color: '#fff',
                  }}
                >
                  {getFormattedDateWithText(promotion)}
                </div>
              </div>
              <div
                style={{
                  marginTop: '24px',
                  color: '#fff',
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '32px',
                  userSelect: 'text',
                  cursor: 'text',
                }}
              >
                {promotion.title}
              </div>
              <div
                style={{
                  marginTop: '16px',
                  color: '#9CA3AF',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  userSelect: 'text',
                  cursor: 'text',
                }}
              >
                {promotion.description}
              </div>
              <img
                src={getBaseUrlStatic() + promotion.image}
                alt={'promotion img'}
                style={{
                  margin: '48px 0px',
                  maxWidth: '640px',
                  maxHeight: '354px',
                  borderRadius: '8px',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  boxSizing: 'border-box',
                  gap: '16px',
                }}
              >
                {getCountDownByType()}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    marginTop: '15px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      padding: isMobile ? '12px 10px' : '12px 16px',
                      background: 'rgba(255, 255, 255, 0.05)',
                      borderRadius: '8px',
                      width: isMobile ? '60px' : '80px',
                    }}
                  >
                    <span
                      style={{
                        color: dateInfo?.days
                          ? '#fff'
                          : 'rgba(255, 255, 255, 0.50)',
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {dateInfo?.days ? dateInfo?.days : '00'}
                    </span>
                    <span
                      style={{
                        color: dateInfo?.days
                          ? '#fff'
                          : 'rgba(255, 255, 255, 0.50)',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {t('days')}
                    </span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      padding: isMobile ? '12px 10px' : '12px 16px',
                      background: 'rgba(255, 255, 255, 0.05)',
                      borderRadius: '8px',
                      width: isMobile ? '60px' : '80px',
                    }}
                  >
                    <span
                      style={{
                        color: dateInfo?.hours
                          ? '#fff'
                          : 'rgba(255, 255, 255, 0.50)',
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {dateInfo?.hours ? dateInfo?.hours : '00'}
                    </span>
                    <span
                      style={{
                        color: dateInfo?.hours
                          ? '#fff'
                          : 'rgba(255, 255, 255, 0.50)',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {t('hours')}
                    </span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      padding: isMobile ? '12px 10px' : '12px 16px',
                      background: 'rgba(255, 255, 255, 0.05)',
                      borderRadius: '8px',
                      width: isMobile ? '60px' : '80px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span
                      style={{
                        color: dateInfo?.minutes
                          ? '#fff'
                          : 'rgba(255, 255, 255, 0.50)',
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {dateInfo?.minutes ? dateInfo?.minutes : '00'}
                    </span>
                    <span
                      style={{
                        color: dateInfo?.minutes
                          ? '#fff'
                          : 'rgba(255, 255, 255, 0.50)',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '24px',
                      }}
                    >
                      {t('minutes')}
                    </span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      padding: isMobile ? '12px 10px' : '12px 16px',
                      background: 'rgba(255, 255, 255, 0.05)',
                      borderRadius: '8px',
                      width: isMobile ? '60px' : '80px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span
                      style={{
                        color: dateInfo?.seconds
                          ? '#fff'
                          : 'rgba(255, 255, 255, 0.50)',
                        fontSize: '24px',
                        fontWeight: 600,
                        lineHeight: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {dateInfo?.seconds ? dateInfo?.seconds : '00'}
                    </span>
                    <span
                      style={{
                        color: dateInfo?.seconds
                          ? '#fff'
                          : 'rgba(255, 255, 255, 0.50)',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '24px',
                      }}
                    >
                      {t('seconds')}
                    </span>
                  </div>
                </div>
              </div>
              <Box
                style={{
                  marginTop: '48px',
                  userSelect: 'text',
                  cursor: 'text',
                }}
                sx={{
                  '& img': {
                    boxSizing: 'border-box',
                    width: '100%',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: promotion.post }}
              />
            </div>
          </div>
        </Container>
      </Box>
    )
  );
};

export default PromotionItemPage;
