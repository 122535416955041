import instance from '../axios';

export const getRakeBacks = () => {
  return instance.get('/rakeback');
};

export const claimRakeBacks = (body: any) => {
  return instance.post('/rakeback/claim', body);
};

export const getCashBacks = () => {
  return instance.get('/cashback');
};

export const claimCashBacks = (body: any) => {
  return instance.post('/cashback/claim', body);
};

export const getAllDepositBonuses = () => {
  return instance.get('/bonus/deposit');
};

export const claimDepositBonuses = (body: any) => {
  return instance.post('/bonus/claim', body);
};

export const getPrizeWithPromo = (body: any) => {
  return instance.post('/bonus/promo', body);
};
