import CustomModal from '../../common/Modal/CustomModal';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';
import { useMemo, useState } from 'react';
import { sendFiles } from '../../../api/services/Settings.api';
import { useSnackbar } from 'notistack';
import { BACK_ID, FRONT_ID, SELFIE } from './constants';
import ModalHeader from '../ModalHeader';
import IdentityItem from './IdentityItem';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';

const useStyles = makeStyles(() => ({
  IdentityModal: {
    backgroundColor: '#111827',
    borderRadius: isMobile ? '' : '12px',
    border: isMobile ? '' : '1px solid #FFFFFF0D',
    paddingBottom: isMobile ? '8px' : '',
    color: '#fff',
    minWidth: isMobile ? '0px' : '528px',
    position: isMobile ? 'absolute' : 'static',
    zIndex: 1,
    inset: 0,
    overflow: isMobile ? 'scroll' : '',
  },
  customButtonBox: {
    display: 'flex',
    margin: '32px 16px 16px',
    '& :first-child': {
      flex: 1,
    },
  },
}));

interface IIdentityModal {
  isOpen: boolean;
  onClose: () => void;
}

const identityModal = ({ isOpen, onClose }: IIdentityModal) => {
  const [loading, setLoading] = useState(false);
  const [frontIdDocument, setFrontIdDocument] = useState<File | null>(null);
  const [backIdDocument, setBackIdDocument] = useState<File | null>(null);
  const [selfieDocument, setSelfieDocument] = useState<File | null>(null);

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const verifyIsAllowed = useMemo(() => {
    return (
      frontIdDocument !== null &&
      backIdDocument !== null &&
      selfieDocument !== null
    );
  }, [frontIdDocument, backIdDocument, selfieDocument]);
  const removeFrontIdDocument = () => {
    setFrontIdDocument(null);
  };

  const removeBackIdDocument = () => {
    setBackIdDocument(null);
  };

  const removeSelfieDocument = () => {
    setSelfieDocument(null);
  };

  const attachDocument = (e: any, documentType: string) => {
    const file = e.target.files?.[0];
    switch (documentType) {
      case FRONT_ID:
        setFrontIdDocument(file);
        break;
      case BACK_ID:
        setBackIdDocument(file);
        break;
      case SELFIE:
        setSelfieDocument(file);
        break;
      default:
        return;
    }
  };

  const fillFormData = () => {
    const formData = new FormData();
    if (frontIdDocument) {
      formData.append('kyc_front', frontIdDocument);
    }
    if (backIdDocument) {
      formData.append('kyc_back', backIdDocument);
    }
    if (selfieDocument) {
      formData.append('kyc_selfie', selfieDocument);
    }
    return formData;
  };

  const handleVerify = () => {
    setLoading(true);
    const formData = fillFormData();

    sendFiles(formData)
      .then((res: any) => {
        enqueueSnackbar(res.data.data.message, {
          variant: 'success',
        });
        onClose();
      })
      .catch((err: any) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const children = (
    <Box className={classes.IdentityModal} id="identityModal">
      <ModalHeader onClose={onClose} heading={t('ProofOfIdentity')} />
      <IdentityItem
        onAttach={(e) => attachDocument(e, FRONT_ID)}
        onRemove={removeFrontIdDocument}
        attachedDocument={frontIdDocument}
        name={t('IDFront')}
        description={t('proofIDFront')}
      />
      <IdentityItem
        onAttach={(e) => attachDocument(e, BACK_ID)}
        onRemove={removeBackIdDocument}
        attachedDocument={backIdDocument}
        name={t('IDBack')}
        description={t('proofIDBack')}
      />
      <IdentityItem
        onAttach={(e) => attachDocument(e, SELFIE)}
        onRemove={removeSelfieDocument}
        attachedDocument={selfieDocument}
        name={t('selfieWithID')}
        description={t('proofSelfie')}
      />
      <Box
        className={cn(classes.customButtonBox, isMobile ? 'mb-[120px]' : '')}
      >
        <CustomPrimaryButton
          disabled={!verifyIsAllowed}
          onClick={handleVerify}
          loading={loading}
          style={{
            textTransform: 'upperCase',
          }}
        >
          <div>{t('verify')}</div>
        </CustomPrimaryButton>
      </Box>
    </Box>
  );

  return isMobile ? (
    children
  ) : (
    <CustomModal open={isOpen} onClose={onClose}>
      {children}
    </CustomModal>
  );
};

export default identityModal;
