import CustomModal from '../../common/Modal/CustomModal';
import { Box, SelectChangeEvent } from '@mui/material';
import CustomPrimaryButton from '../../common/Buttons/CustomPrimaryButton';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import CustomSettingsSelect from '../CustomSettingsSelect';
import { ICountry, IUserInfo } from '../../../types/common.type';
import { personalInfo } from '../../../api/services/Settings.api';
import { useSnackbar } from 'notistack';
import ModalHeader from '../ModalHeader';
import { isMobile } from 'react-device-detect';
import PersonalInput from './PersonalInput';
import cn from 'classnames';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '24px',
    color: '#9CA3AF',
    fontWeight: 600,
    padding: '0px 16px',
  },
  PersonalModal: {
    color: '#fff',
    backgroundColor: '#111827',
    borderRadius: isMobile ? '' : '12px',
    border: isMobile ? '' : '1px solid #FFFFFF0D',
    paddingBottom: isMobile ? '8px' : '',
    minWidth: isMobile ? '0px' : '528px',
    position: isMobile ? 'absolute' : 'static',
    zIndex: 1,
    inset: 0,
    overflow: isMobile ? 'scroll' : '',
  },
  sectionContainer: {
    marginTop: '16px',
    display: 'flex',
    gap: '16px',
    padding: '0 16px',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  inputContainer: {
    marginTop: '4px',
    display: 'flex',
    width: '100%',
  },
  input: {
    border: 'none',
    backgroundColor: '#0D121D',
    outline: 'none',
    color: '#fff',
    paddingLeft: '16px',
    height: '48px',
    borderRadius: '8px',
    flex: 1,
    width: '100%',
  },
  customButtonBox: {
    display: 'flex',
    margin: '32px 16px 16px',
    '& :first-child': {
      flex: 1,
    },
  },
  countriesList: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    '& div': {
      display: 'flex',
      height: '16px',
      width: '24px',
      overflow: 'hidden',
      '& img': {
        height: '100%',
        width: '100%',
      },
    },
  },
}));

interface IPersonalModal {
  isOpen: boolean;
  onClose: () => void;
  countries: ICountry[];
  playerInfo: IUserInfo;
}

const PersonalModal = ({
  isOpen,
  onClose,
  countries,
  playerInfo,
}: IPersonalModal) => {
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState<string>(playerInfo.player.gender || '');
  const [currentCountry, setCurrentCountry] = useState<string>('');
  const [firstName, setFirstName] = useState<string>(
    playerInfo.player.first_name || ''
  );
  const [lastName, setLastName] = useState<string>(
    playerInfo.player.last_name || ''
  );

  const [day, setDay] = useState<string>(
    playerInfo.player.birthday?.toString().split('-')[2] || ''
  );
  const [month, setMonth] = useState<string>(
    playerInfo.player.birthday?.toString().split('-')[1] || ''
  );
  const [year, setYear] = useState<string>(
    playerInfo.player.birthday?.toString().split('-')[0] || ''
  );
  const [address, setAddressName] = useState<string>(
    playerInfo.player.address || ''
  );
  const [city, setCity] = useState<string>(playerInfo.player.city || '');
  const [postalCode, setPostalCode] = useState<string>(
    playerInfo.player.postal_code || ''
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const genders = ['Male', 'Female'];

  const countryIso = useMemo(() => {
    const res = countries.find((country) => country.name === currentCountry);
    if (res) {
      return res.iso;
    }
    return '';
  }, [currentCountry]);

  const changeYearHandler = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    if (/^[1-9]\d{4}$/.test(numericValue) || numericValue === '0') return;
    setYear(numericValue);
  };

  const changeDayHandler = (value: string) => {
    if (value === '00') {
      setDay('0');
      return;
    }
    const numericValue = value.replace(/\D/g, '');
    if (/^[0-9]\d{2}$/.test(numericValue)) return;
    setDay(numericValue);
  };

  const changeMonthHandler = (value: string) => {
    if (value === '00') {
      setMonth('0');
      return;
    }
    const numericValue = value.replace(/\D/g, '');
    if (/^[0-9]\d{2}$/.test(numericValue)) return;
    setMonth(numericValue);
  };

  const updatePersonalInfo = () => {
    if (
      Number(day) > 31 ||
      Number(month) > 12 ||
      Number(year) > new Date().getFullYear() ||
      Number(year) < 1900
    ) {
      enqueueSnackbar(t('invalidDateFormat'), {
        variant: 'error',
      });
      return;
    }
    setLoading(true);

    personalInfo({
      first_name: firstName,
      last_name: lastName,
      country: countryIso,
      gender: gender,
      birthday: year && month && day ? `${year}-${month}-${day}` : '',
      address: address,
      city: city,
      postal_code: postalCode,
    })
      .then((res) => {
        if (res.data.success) {
          enqueueSnackbar(t(res.data.data.message), {
            variant: 'success',
          });
          onClose();
        }
      })
      .catch(() => {
        enqueueSnackbar(t('fillAllFields'), {
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  const gendersData = () => {
    return genders.map((gender) => {
      return {
        value: gender,
        renderer: () => <Box color={'#9CA3AF'}>{gender}</Box>,
      };
    });
  };

  useEffect(() => {
    const country = countries.find(
      (country) => country.iso === playerInfo.player.country
    );
    setCurrentCountry(country?.name || '');
  }, []);

  const countriesData = () => {
    return countries.map((country) => {
      return {
        value: country.name,
        renderer: () => (
          <Box width={'100%'} color={'#9CA3AF'}>
            <div className={classes.countriesList}>
              <span>{country.name}</span>
              <div>
                <img
                  src={`/imgs/Flags/${country.iso.toLowerCase()}.png`}
                  alt="flag"
                />
              </div>
            </div>
          </Box>
        ),
      };
    });
  };

  const onChangeGender = (e: SelectChangeEvent) => {
    setGender(e.target.value as string);
  };

  const onChangeCountry = (e: SelectChangeEvent) => {
    setCurrentCountry(e.target.value as string);
  };

  const children = (
    <Box className={cn(classes.PersonalModal, '!h-full !min-h-full')} id="personalModal" >
      <ModalHeader onClose={onClose} heading={t('personalInformation')} />
      <Box
        className={`${classes.sectionContainer} ${isMobile ? classes.flexColumn : ''}`}
      >
        <PersonalInput
          onChange={(e) => setFirstName(e.target.value)}
          label={t('firstName')}
          placeHolder={t('enterFirstName')}
          value={firstName}
        />
        <PersonalInput
          onChange={(e) => setLastName(e.target.value)}
          label={t('lastName')}
          placeHolder={t('enterLastName')}
          value={lastName}
        />
      </Box>
      <Box className={classes.sectionContainer}>
        <Box flex={1}>
          <div className={classes.title}>{t('dateOfBirth')}</div>
          <div className={classes.inputContainer} style={{ gap: '16px' }}>
            <input
              className={classes.input}
              placeholder={'DD'}
              value={day}
              onChange={(e) => changeDayHandler(e.target.value)}
            />
            <input
              className={`${classes.input}`}
              placeholder={'MM'}
              value={month}
              onChange={(e) => changeMonthHandler(e.target.value)}
            />
            <input
              className={`${classes.input}`}
              placeholder={'YYYY'}
              value={year}
              onChange={(e) => changeYearHandler(e.target.value)}
            />
          </div>
        </Box>
      </Box>
      <Box className={classes.sectionContainer}>
        <Box width={'100%'}>
          <div className={classes.title}>{t('gender')}</div>
          <CustomSettingsSelect
            onChange={onChangeGender}
            value={
              gender
                ? gender
                : playerInfo.player.gender
                  ? playerInfo.player.gender
                  : ''
            }
            data={gendersData()}
            separated={false}
          />
        </Box>
      </Box>
      <Box className={classes.sectionContainer}>
        <Box width={'100%'}>
          <div className={classes.title}>{t('countryOfResidence')}</div>
          <CustomSettingsSelect
            onChange={onChangeCountry}
            value={currentCountry}
            data={countriesData()}
            separated={false}
          />
        </Box>
      </Box>
      <Box marginTop={'16px'} padding={'0 16px'}>
        <PersonalInput
          onChange={(e) => setAddressName(e.target.value)}
          label={t('streetAddress')}
          placeHolder={t('enterStreetAddress')}
          value={address}
        />
      </Box>
      <Box
        className={`${classes.sectionContainer} ${isMobile ? classes.flexColumn : ''}`}
      >
        <PersonalInput
          onChange={(e) => setCity(e.target.value)}
          label={t('city')}
          placeHolder={t('enterCity')}
          value={city}
        />
        <PersonalInput
          onChange={(e) => setPostalCode(e.target.value)}
          label={t('postCode')}
          placeHolder={t('enterPostcode')}
          value={postalCode}
        />
      </Box>
      <Box className={cn(classes.customButtonBox, isMobile ? 'mb-[120px]' : '')}>
        <CustomPrimaryButton
          onClick={updatePersonalInfo}
          loading={loading}
          style={{
            textTransform: 'upperCase',
          }}
        >
          <div>{t('verify')}</div>
        </CustomPrimaryButton>
      </Box>
    </Box>
  );

  return isMobile ? (
    children
  ) : (
    <CustomModal open={isOpen} onClose={onClose}>
      {children}
    </CustomModal>
  );
};

export default PersonalModal;
