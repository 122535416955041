import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@mui/material';
import BuyCryptoIcon from '../../api/ui/svgIcons/BuyCryptoIcon';
import React, { ChangeEvent } from 'react';
import CustomInput from '../common/Inputs/CustomInput';
import AirbetIcon from '../../api/ui/svgIcons/NotificationIcons/AirbetIcon';
import { IPaymentOffers } from '../../types/common.type';
import MasterCardIcon from '../../api/ui/svgIcons/CryptoIcons/MasterCardIcon';
import VisaIcon from '../../api/ui/svgIcons/CryptoIcons/VisaIcon';
import ApplePayIcon from '../../api/ui/svgIcons/CryptoIcons/ApplePayIcon';
import GooglePayIcon from '../../api/ui/svgIcons/CryptoIcons/GooglePayIcon';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import MinimumInfoAmountIcon from '../../api/ui/svgIcons/MinimumInfoAmountIcon';
import { isMobile } from 'react-device-detect';
import QuestionMarkIcon from '../../api/ui/svgIcons/QuestoinMartIcon';

interface IBuyCrypto {
  amount: number | null;
  setAmount: (amount: number) => void;
  loadingOffers: boolean;
  offers: IPaymentOffers[] | null;
  selectedOffer: IPaymentOffers | null;
  setSelectedOffer: (offer: IPaymentOffers) => void;
  onConfirm: () => void;
  confirmLoading: boolean;
  hideHeader?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    ...theme.general.accountBody,
  },
  rootMobile: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    background: '#111827',
  },
  header: {
    margin: '12px 0px',
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'space-between',
    '& span': {
      color: '#fff',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  disableTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  disableTextContainerHeader: {
    padding: '0px 16px',
    color: '#9CA3AF',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  disableTextContainerBody: {
    height: '48px',
    background: '#0D121D',
    borderRadius: '8px',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    padding: '0px 16px',
    alignItems: 'center',
    display: 'flex',
  },
  paymentOffers: {
    color: '#9CA3AF',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    paddingLeft: '16px',
    paddingBottom: '8px',
  },
  providerCode: {
    color: '#fff',
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: '16px',
    textTransform: 'uppercase',
  },
  amountExpectedTo: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',
  },
  offerContainer: {
    background: '#0D121D',
    padding: '12px 16px 12px 16px',
    borderRadius: '8px',
    cursor: 'pointer',
    border: '1px solid #0D121D',
    transition: 'border-color 0.3s ease-in-out',
  },
  paymentMethods: {
    color: '#9CA3AF',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',
    marginTop: '8px',
    marginBottom: '24px',
  },
  icon: {
    fill: '#fff',
  },
  minimumAmountContainer: {
    background: '#FFFFFF0D',
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  minimumAmountContainerText: {
    color: '#FFFFFF80',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  minimumAmountContainerAmount: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
  },
  minimumAmountContainerAmountText: {
    color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  hideHeader: {
    border: 'none',
  },
  debitCreditCardBtn: {
    height: '32px',
    maxWidth: !isMobile ? '700px' : '500px',
    cursor: 'pointer',
    borderRadius: '8px',
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: 'rgba(31, 41, 55, 1)',
  },
  debitCreditCardText: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    color: '#fff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const BuyCrypto = ({
  amount,
  setAmount,
  loadingOffers,
  offers,
  selectedOffer,
  setSelectedOffer,
  onConfirm,
  confirmLoading,
  hideHeader,
}: IBuyCrypto) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const goToHowToDeposit = () => {
    window.open(
      'https://6746e917056f1eb95a1123bc.kb.help/how-to-make-a-deposit/',
      '_blank'
    );
  };

  return (
    <div
      className={
        !hideHeader
          ? isMobile
            ? classes.rootMobile
            : classes.root
          : classes.hideHeader
      }
      style={isMobile && hideHeader ? {position: 'absolute', left: 0, top: 72, right: 0, bottom: 0} : {}}
    >
        {!isMobile && !hideHeader && (
          <div className={classes.header}>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <BuyCryptoIcon size={20} />
              <span>{t('BuyCrypto')}</span>
            </Box>

            <Box
              className={classes.debitCreditCardBtn}
              onClick={goToHowToDeposit}
            >
              <QuestionMarkIcon size={18} fill={'rgba(156, 163, 175, 1)'} />
              <span className={classes.debitCreditCardText}>
                {t('howToDepositWithDebitCreditCard')}
              </span>
            </Box>
          </div>
        )}
        {!isMobile && !hideHeader && (
          <Box
            style={{
              background: '#FFFFFF0D',
              height: '1px',
              width: '100%',
            }}
          />
        )}
        <Box
          display={'flex'}
          flexDirection={'column'}
          padding={isMobile ? '16px 8px' : '16px'}
          gap={'24px'}
          // maxHeight={isMobile ? 'calc(100vh - 215px)' : 'calc(100vh - 500px)'}
          maxHeight={isMobile ? 'calc(100vh - 215px)' : 'calc(100vh - 500px)'}
          // overflow={'auto'}
          overflow={isMobile ? 'scroll' : 'auto'}
        >
          <Box
            display={'flex'}
            gap={'24px'}
            width={'100%'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            {isMobile && (
              <Box
                className={classes.debitCreditCardBtn}
                onClick={goToHowToDeposit}
              >
                <QuestionMarkIcon size={18} fill={'rgba(156, 163, 175, 1)'} />
                <span className={classes.debitCreditCardText}>
                  {t('howToDepositWithDebitCreditCard')}
                </span>
              </Box>
            )}

            <Box className={classes.disableTextContainer} flex={1}>
              <span className={classes.disableTextContainerHeader}>
                {t('youSend')}
              </span>
              <Box className={classes.disableTextContainerBody} gap={'8px'}>
                <img src="/imgs/dollar.png" alt="dollar" />
                <span
                  style={{
                    fontSize: '14px',
                    lineHeight: '24px',
                  }}
                >
                  {t('usd')}
                </span>
                <span
                  style={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#9CA3AF',
                  }}
                >
                  USD
                </span>
              </Box>
            </Box>
            <Box flex={1}>
              <CustomInput
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setAmount(Number(event.target.value))
                }
                label={t('amount')}
                type={'number'}
                value={amount ? amount : ''}
                placeholder={'0.00'}
              />
            </Box>
          </Box>
          <Box className={classes.minimumAmountContainer}>
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              <MinimumInfoAmountIcon />
              <span className={classes.minimumAmountContainerAmount}>30 USD</span>
              <span className={classes.minimumAmountContainerAmountText}>
                {t('minimumBuyCrypto')}
              </span>
            </Box>
            {!isMobile && (
              <Box className={classes.minimumAmountContainerText}>
                {t('minimumBuyCryptoDescription')}
              </Box>
            )}
          </Box>
          <Box className={classes.disableTextContainer}>
            <span className={classes.disableTextContainerHeader}>
              {t('youGet')}
            </span>
            <Box className={classes.disableTextContainerBody} gap={'8px'}>
              <AirbetIcon size={24} />
              <span
                style={{
                  fontSize: '14px',
                  lineHeight: '24px',
                }}
              >
                {t('airbetUSD')}
              </span>
            </Box>
          </Box>
          <Box>
            {loadingOffers ? (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
                height={'146px'}
              >
                <CircularProgress />
              </Box>
            ) : (
              offers && (
                <Box display={'flex'} width={'100%'} flexDirection={'column'}>
                  <span className={classes.paymentOffers}>
                    {t('paymentOffers')}
                  </span>
                  <Box
                    style={{
                      display: 'grid',
                      gridTemplateColumns: isMobile ? '' : 'repeat(2, 1fr)',
                      gap: '24px',
                      flexDirection: isMobile ? 'column' : 'row',
                    }}
                  >
                    {offers.map((offer: IPaymentOffers, index: number) => {
                      return (
                        <Box
                          key={index}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          className={classes.offerContainer}
                          style={{
                            borderColor:
                              selectedOffer &&
                              selectedOffer.providerCode === offer.providerCode
                                ? '#1D4ED8'
                                : '',
                          }}
                          onClick={() => setSelectedOffer(offer)}
                        >
                          <span className={classes.providerCode}>
                            {offer.providerCode}
                          </span>
                          <span className={classes.amountExpectedTo}>
                            {offer.amountExpectedTo}
                          </span>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    marginTop={'28px'}
                    marginBottom={'12px'}
                  >
                    <span
                      style={{
                        color: '#6B7280',
                        fontSize: '12px',
                        lineHeight: '24px',
                      }}
                    >
                      {t('airbetUSDEqualToUsd')}
                    </span>
                  </Box>
                </Box>
              )
            )}
            {isMobile && (
              <Box
              height={'60px'}
              width={'100%'}
              />
            )}
          </Box>
        </Box>
        <Box
          style={{
            background: '#FFFFFF0D',
            width: '100%',
            height: '1px',
          }}
        />
        {!isMobile && (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            padding={'16px'}
            flexDirection={'column'}
            style={{
              boxShadow: 'rgb(19 19 19 / 55%) 0px -7px 9px 0px',
            }}
          >
            <span className={classes.paymentMethods}>{t('paymentMethods')}</span>
            <Box display={'flex'} alignItems={'center'} gap={'32px'}>
              <MasterCardIcon pathClassName={classes.icon} />
              <VisaIcon pathClassName={classes.icon} />
              <ApplePayIcon pathClassName={classes.icon} />
              <GooglePayIcon />
            </Box>
          </Box>
        )}
        <Box
          style={{background: '#0D121D'}}
          position={isMobile ? 'fixed' : 'static'}
          bottom={60}
          left={0}
          right={0}
        >
          <Box
            style={{
              background: '#FFFFFF0D',
              width: '100%',
              height: '1px',
            }}
          />
          {/* Last Box */}
          <Box
            padding={'16px'}
            justifyContent={'start'}
            width={'100%'}
            display={'flex'}
            boxSizing={'border-box'}
            style={{
              boxShadow: isMobile ? 'rgb(19 19 19 / 55%) 0px -7px 9px 0px' : '',
            }}
          >
            <CustomPrimaryButton
              style={{
                textTransform: 'uppercase',
                width: isMobile ? '100%' : '',
              }}
              disabled={!selectedOffer}
              loading={confirmLoading}
              onClick={() => onConfirm()}
              fullWidth={isMobile ? '100%' : 'auto'}
            >
              {t('confirm')}
            </CustomPrimaryButton>
          </Box>
        </Box>
    </div>
  );
};

export default BuyCrypto;
