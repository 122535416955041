import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';

const Affiliate = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('useEffect triggered');
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const stag = url.searchParams.get('stag');
    if (stag) {
      const data = {
        date: new Date(),
        code: stag,
      };
      localStorage.setItem('stag', JSON.stringify(data));
    }
    console.log('Navigating to home');
    // navigate(AppRoutePath.HOME());
    window.location.href = AppRoutePath.HOME();
  }, [navigate]);
  return <div />;
};

export default Affiliate;
