import CustomMinimalButton from '../common/Buttons/CustomMinimalButton';
import { isMobile } from 'react-device-detect';

interface ISettingsButton {
  action: () => void;
  buttonText: string;
  disabled?: boolean;
  width?: string;
}

const SettingsButton = ({ action, buttonText, disabled, width }: ISettingsButton) => {
  return (
    <>
      <CustomMinimalButton
        onClick={action}
        style={{
          textTransform: 'uppercase',
          padding: '8px 24px',
          flex: 1,
          borderRadius: isMobile ? '12px' : '8px',
          height: '40px',
          width: width ? width : '100%',
        }}
        disabled={disabled}
      >
        {buttonText}
      </CustomMinimalButton>
    </>
  );
};

export default SettingsButton;
