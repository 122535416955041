import { Box, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import WalletIcon from '../../api/ui/svgIcons/WalletIcon';
import { useTranslation } from 'react-i18next';
import DepositIcon from '../../api/ui/svgIcons/DepositIcon';
import WithdrawIcon from '../../api/ui/svgIcons/WithdrawIcon';
import TransactionIcon from '../../api/ui/svgIcons/TransactionIcon';
import SettingIcon from '../../api/ui/svgIcons/SettingIcon';
import LogOutIcon from '../../api/ui/svgIcons/LogOutIcon';
import BuyCryptoIcon from '../../api/ui/svgIcons/BuyCryptoIcon';
import { useNavigate } from 'react-router-dom';
import { AppRoutePath } from '../../util/appRoutePath';
import CustomModal from '../common/Modal/CustomModal';
import { SignOut } from '../../api/services/Auth.api';
import { setLocalStorageValue } from '../../util/appUtil';
import { bindActionCreators } from 'redux';
import {
  changeAccessToken,
  changeUserAuthorization,
  changeUserProfileInfo,
  clearUserInfo,
} from '../../redux/actions/userActions';
import { connect } from 'react-redux';
import { IAccessToken, IRankInfo, IUserInfo } from '../../types/common.type';
import UserProfileHeader from '../UserProfile/UserProfileHeader';
import UserRank from '../UserProfile/UserRank';
import { IUserProfileInfo, RANKS } from '../../util/UserProfileHelper';
import { getBaseUrlStatic } from '../../api/baseUrl';
import CircularProgress from '@mui/material/CircularProgress';

interface IAppHeaderAccountInfoMenu {
  changeUserAuthorizationFunction: (isAuthorized: boolean) => void;
  changeUserAccessToken: (data: IAccessToken) => void;
  clearUserInfo: () => void;
  userInfo: IUserInfo;
  ranksInfo: IRankInfo[];
  changeUserProfileInfo: (data: IUserProfileInfo) => void;
}

const useStyles = makeStyles(() => ({
  profileBtn: {
    background: '#374151',
    width: '40px',
    height: '40px',
    maxHeight: '40px',
    maxWidth: '40px',
    borderRadius: '48px',
    cursor: 'pointer',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    minWidth: '0px',
    boxSizing: 'border-box',
  },
  menuItem: {
    display: 'flex',
    padding: '7px 8px',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '10px',
    width: '100%',
    minWidth: '0px',
    boxSizing: 'border-box',
    border: '1px solid #111827',
    cursor: 'pointer',
    transition: 'border-color 0.3s ease',
    '&:hover': {
      background: '#111827',
      borderColor: '#1F2937',
      '& span': {
        color: '#fff !important',
      },
    },
  },
  menuItemText: {
    color: '#9CA3AF',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    transition: 'color 0.3s ease',
  },
  rankContainer: {
    height: '208px',
    width: '100%',
    background: '#0D121D',
    borderRadius: '8px',
  },
  userInfoContainer: {
    background: '#0D121D',
    height: '56px',
    borderRadius: '8px',
    width: '100%',
  },
  signOutContainer: {
    width: '364px',
    height: '239px',
    background: '#0D121D',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderRadius: '8px',
    padding: '10px',
    gap: '8px',
  },
  logOutBtn: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '12px',
    padding: '8px 24px',
    background: '#111827',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& span': {
      fontSize: '14px',
      fontWeight: 700,
      color: '#fff',
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
  },
}));
const AppHeaderAccountInfoMenu = ({
  clearUserInfo,
  changeUserAuthorizationFunction,
  changeUserAccessToken,
  userInfo,
  ranksInfo,
  changeUserProfileInfo,
}: IAppHeaderAccountInfoMenu) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openLogOutModal, setOpenLogOutModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogOut = () => {
    setLoading(true);
    SignOut()
      .then(() => {
        setOpenLogOutModal(false);
        changeUserAuthorizationFunction(false);
        changeUserAccessToken({
          expire_at: '',
          token: '',
        });
        clearUserInfo();
        setLocalStorageValue('isAuthorized', false);
        setLocalStorageValue('token_expire_at', '');
        setLocalStorageValue('userId', '');
        setLocalStorageValue('token', '');
        setLocalStorageValue('userInfo', '');
        navigate(AppRoutePath.HOME());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNextRankName = () => {
    const currRankIndex: number = RANKS.findIndex(
      (rank: any) => rank.name === userInfo.player.rank.rank
    );

    if (currRankIndex + 1 >= RANKS.length) return '';
    return RANKS[currRankIndex + 1].name;
  };

  const getMaxRankLevel = () => {
    const currentUserRankName: string = userInfo.player.rank.rank;
    return ranksInfo.find(
      (rank: IRankInfo) => rank.name === currentUserRankName
    )?.max_level;
  };

  const onClickProfile = () => {
    changeUserProfileInfo({
      open: true,
      isCurrentUser: true,
      id: userInfo.player.id,
      username: userInfo.player.username,
      totalWins: userInfo.player.total_win,
      totalBets: userInfo.player.total_bet,
      avatar: userInfo.player.avatar,
      rank: userInfo.player.rank,
    });
    handleClose();
  };

  return (
    userInfo.player &&
    userInfo.player.id && (
      <div>
        <Box 
          className={classes.profileBtn} onClick={handleClick} 
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <img
            alt={'profile img'}
            src={
              userInfo.player.avatar
                ? userInfo.player.avatar.includes('google')
                  ? userInfo.player.avatar
                  : getBaseUrlStatic() + userInfo.player.avatar
                : '/imgs/DefaultAvatars/Avatar.png'
            }
            style={{
              maxWidth: '40px',
              maxHeight: '40px',
              borderRadius: '100%'
            }}
          />
        </Box>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '.MuiPopover-paper': {
              marginTop: '10px',
              width: '272px',
              maxHeight: '624px',
              borderRadius: '8px',
              padding: '8px',
              border: '1px solid #1F2937',
              background: '#111827',
              boxShadow: '0px 16px 32px 0px rgba(0, 0, 0, 0.10)',
            },
          }}
        >
          <Box className={classes.menuContainer}>
            <Box className={classes.userInfoContainer}>
              <UserProfileHeader
                userId={String(userInfo.player.id)}
                username={userInfo.player.username}
                img={userInfo.player.avatar}
                stage={'DEFAULT'}
                onClickProfile={() => onClickProfile()}
              />
            </Box>
            <Box className={classes.rankContainer}>
              <UserRank
                currentRankName={userInfo.player.rank.rank}
                nextRankName={getNextRankName()}
                srcInfo={RANKS.find(
                  (item) => item.name === userInfo.player.rank.rank
                )}
                level={userInfo.player.rank.level}
                progress={userInfo.player.rank.progress}
                maxLevel={getMaxRankLevel() || 0}
              />
            </Box>
            <Box
              className={classes.menuItem}
              onClick={() => {
                navigate(AppRoutePath.WALLET());
                handleClose();
              }}
            >
              <WalletIcon />
              <span className={classes.menuItemText}>{t('wallet')}</span>
            </Box>
            <Box
              className={classes.menuItem}
              onClick={() => {
                navigate(AppRoutePath.DEPOSIT());
                handleClose();
              }}
            >
              <DepositIcon />
              <span className={classes.menuItemText}>{t('deposit')}</span>
            </Box>
            <Box
              className={classes.menuItem}
              onClick={() => {
                navigate(AppRoutePath.WITHDRAW());
                handleClose();
              }}
            >
              <WithdrawIcon />
              <span className={classes.menuItemText}>{t('withdraw')}</span>
            </Box>
            <Box
              className={classes.menuItem}
              onClick={() => {
                navigate(AppRoutePath.BUYCRYPTO());
                handleClose();
              }}
            >
              <BuyCryptoIcon />
              <span className={classes.menuItemText}>{t('buyCrypto')}</span>
            </Box>
            <Box
              className={classes.menuItem}
              onClick={() => {
                navigate(AppRoutePath.TRANSACTIONS());
                handleClose();
              }}
            >
              <TransactionIcon />
              <span className={classes.menuItemText}>{t('transactions')}</span>
            </Box>
            <Box
              className={classes.menuItem}
              onClick={() => {
                navigate(AppRoutePath.ACCOUNT_INFO());
                handleClose();
              }}
            >
              <SettingIcon />
              <span className={classes.menuItemText}>{t('settings')}</span>
            </Box>
            <Box
              className={classes.menuItem}
              onClick={() => {
                handleClose();
                setOpenLogOutModal(true);
              }}
            >
              <LogOutIcon />
              <span className={classes.menuItemText}>{t('logOut')}</span>
            </Box>
          </Box>
        </Popover>
        <CustomModal
          open={openLogOutModal}
          onClose={() => setOpenLogOutModal(false)}
        >
          <Box className={classes.signOutContainer}>
            <span
              style={{
                fontSize: '24px',
                fontWeight: 600,
                color: '#fff',
                padding: '0px 16px',
              }}
            >
              {t('logOut')}
            </span>
            <span
              style={{
                fontWeight: 500,
                color: '#fff',
                marginTop: '16px',
                padding: '0px 16px',
                marginBottom: '16px',
              }}
            >
              {t('areYouSureLogOut')}
            </span>
            <Box
              className={classes.logOutBtn}
              onClick={() => onLogOut()}
              style={{}}
            >
              {loading ? (
                <CircularProgress style={{ width: '24px', height: '24px' }} />
              ) : (
                <span>{t('yesSignOut')}</span>
              )}
            </Box>
            <Box
              className={classes.logOutBtn}
              onClick={() => setOpenLogOutModal(false)}
            >
              <span>{t('cancel')}</span>
            </Box>
          </Box>
        </CustomModal>
      </div>
    )
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.user.playerInfo,
  ranksInfo: state.user.ranksInfo,
});

const dispatchToProps = (dispatch: any) => ({
  changeUserAccessToken: bindActionCreators(changeAccessToken, dispatch),
  clearUserInfo: bindActionCreators(clearUserInfo, dispatch),
  changeUserAuthorizationFunction: bindActionCreators(
    changeUserAuthorization,
    dispatch
  ),
  changeUserProfileInfo: bindActionCreators(changeUserProfileInfo, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchToProps
)(AppHeaderAccountInfoMenu);
