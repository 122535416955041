import HeaderButtonContainer from '../common/ButtonContainer/HeaderButtonContainer';
import ChatIcon from '../../api/ui/svgIcons/ChatIcon';
import CustomSecondaryButton from '../common/Buttons/CustomSecondaryButton';
import { useTranslation } from 'react-i18next';
import CustomPrimaryButton from '../common/Buttons/CustomPrimaryButton';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import Lottie from 'react-lottie';
import bonusAnimation from '../../lotties/bonus-animation.json';
import { defaultOptions } from '../../lotties/options';

interface IAppHeaderUnAuthorizedContent {
  isChatOpen: boolean;
  setIsChatOpen: (isOpen: boolean) => void;
  openLogin: () => void;
  openRegistration: () => void;
}

const AppHeaderUnAuthorizedContent = ({
  isChatOpen,
  setIsChatOpen,
  openLogin,
  openRegistration,
}: IAppHeaderUnAuthorizedContent) => {
  const { t } = useTranslation();

  return (
    <Box
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'end'}
      paddingRight={isMobile ? '0px' : '24px'}
    >
      <CustomSecondaryButton
        style={{ textTransform: 'uppercase', marginRight: '8px' }}
        onClick={() => openLogin()}
      >
        {t('header.login')}
      </CustomSecondaryButton>

      <CustomPrimaryButton
        style={{
          textTransform: 'uppercase',
          marginRight: isMobile ? '0px' : '24px',
          paddingRight: isMobile ? '30px' : '42px',
        }}
        onClick={() => openRegistration()}
      >
        {t('header.joinNow')}
        <Lottie
          options={{ ...defaultOptions, animationData: bonusAnimation }}
          height={isMobile ? 25 : 35}
          width={isMobile ? 25 : 35}
          style={{
            position: 'absolute',
            top: isMobile ? '2px' : 0,
            right: '2px',
          }}
        />
      </CustomPrimaryButton>

      {!isMobile && (
        <HeaderButtonContainer onClick={() => setIsChatOpen(!isChatOpen)}>
          <ChatIcon fill={'#fff'} />
        </HeaderButtonContainer>
      )}
    </Box>
  );
};

export default AppHeaderUnAuthorizedContent;
